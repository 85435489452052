const moveWindow = () => {

  // Make the DIV element draggable:
  const firstWindow = document.getElementById("first-window");
  const secondWindow = document.getElementById("second-window");
  const thirdWindow = document.getElementById("third-window");
  const fourthWindow = document.querySelector('#fourth-window');
  const fifthWindow = document.querySelector('#fifth-window');
  const sixthWindow = document.querySelector('#sixth-window');
  const seventhWindow = document.querySelector('#seventh-window');

  const windows = [firstWindow, secondWindow, thirdWindow, fourthWindow, fifthWindow, sixthWindow, seventhWindow];


  if (document.getElementById("first-window")) {
    windows.forEach(window => {
      dragElement(window);
    })
  }

  if (document.getElementById("first-window")) {
    window.addEventListener('resize', function(event){
        windows.forEach(window => window.style.top = null);
        windows.forEach(window => window.style.left = null);
    });
  }

  function dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "-header")) {
        // if present, the header is where you move the DIV from:
        document.getElementById(elmnt.id + "-header").onmousedown = dragMouseDown;
      } else {
        // otherwise, move the DIV from anywhere inside the DIV:
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }


      function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        if (window.innerWidth > 1100) {
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        } else {
          elmnt.style.top = null;
          elmnt.style.left = null;
        }
      }

      function closeDragElement() {
        // stop moving when mouse button is released:
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
}

export { moveWindow }
