const initNavbar = () => {

  //  window and desktop links
  const firstWindow = document.querySelector('#first-window');
  const secondWindow = document.querySelector('#second-window');
  const thirdWindow = document.querySelector('#third-window');
  const fourthWindow = document.querySelector('#fourth-window');
  const fifthWindow = document.querySelector('#fifth-window');
  const sixthWindow = document.querySelector('#sixth-window');
  const seventhWindow = document.querySelector('#seventh-window');

  const windows = [firstWindow, secondWindow, thirdWindow, fourthWindow, fifthWindow, sixthWindow, seventhWindow];

if (document.getElementById("first-window")) {
  if (document.getElementById("toggle") !=null) {
    document.getElementById("toggle").addEventListener('change', function() {
      if (this.checked) {
        windows.forEach(window => {
        window.style.display = "none";
      })

      }
   });
  }

}


}

export { initNavbar };





