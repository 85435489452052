const putWindowOnTop = () => {


const windowOnTop = document.querySelectorAll('.window');


  windowOnTop.forEach((input) => {
    input.addEventListener('click', (event) => {


      windowOnTop.forEach((input) => {
        if (input.style.zIndex === "50") {
          input.style.zIndex = "40";
        } else if (input.style.zIndex === "40"){
          input.style.zIndex = "30";
        } else {
          input.style.zIndex = "20";
        }
      });
        input.style.zIndex = "50";
    });
  })
}

export { putWindowOnTop }
