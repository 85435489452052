// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// windows
import { moveWindow } from '../windows/move_window';
import { openWindow } from '../windows/open_window';
import { putWindowOnTop } from '../windows/put_window_on_top';
import { closeWindow} from '../windows/close_window';

// navbar
import { initNavbar } from '../components/init_navbar';


document.addEventListener('turbolinks:load', () => {
  initNavbar();
  //windows
  moveWindow();
  openWindow();
  putWindowOnTop();
  closeWindow();
})
