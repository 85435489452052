const closeWindow = () => {

  const firstCross = document.querySelector('#first-cross');
  const secondCross = document.querySelector('#second-cross');
  const thirdCross = document.querySelector('#third-cross');
  const fourthCross = document.querySelector('#fourth-cross');
  const fifthCross = document.querySelector('#fifth-cross');
  const sixthCross = document.querySelector('#sixth-cross');
  const seventhCross = document.querySelector('#seventh-cross');
  const firstWindow = document.querySelector('#first-window');
  const secondWindow = document.querySelector('#second-window');
  const thirdWindow = document.querySelector('#third-window');
  const fourthWindow = document.querySelector('#fourth-window');
  const fifthWindow = document.querySelector('#fifth-window');
  const sixthWindow = document.querySelector('#sixth-window');
  const seventhWindow = document.querySelector('#seventh-window');

  const crosses = [firstCross, secondCross, thirdCross, fourthCross, fifthCross, sixthCross, seventhCross];
  const windows = [firstWindow, secondWindow, thirdWindow, fourthWindow, fifthWindow, sixthWindow, seventhWindow];

// get index of chosen Font
  const crossValues = (x) => {
    return crosses.indexOf(x);
  };
//  get value of chosen Font
  const windowToClose = (x) => {
    return windows[crossValues(x)];
  };

  if (document.getElementById("first-window")) {
    crosses.forEach(cross => cross.addEventListener('click', function() {
      windowToClose(cross).style.display = "none";
    }))
  }
}

export { closeWindow }


