const openWindow = () => {

//  window and desktop links
const firstWindow = document.querySelector('#first-window');
const firstButton = document.querySelector('#first-link');
const secondWindow = document.querySelector('#second-window');
const secondButton = document.querySelector('#second-link');
const thirdWindow = document.querySelector('#third-window');
const thirdButton = document.querySelector('#third-link');
const fourthWindow = document.querySelector('#fourth-window');
const fourthButton = document.querySelector('#fourth-link');
const fifthWindow = document.querySelector('#fifth-window');
const fifthButton = document.querySelector('#fifth-link');
const sixthWindow = document.querySelector('#sixth-window');
const sixthButton = document.querySelector('#sixth-link');
const seventhWindow = document.querySelector('#seventh-window');
const seventhButton = document.querySelector('#seventh-link');

const Windows = [firstWindow, secondWindow, thirdWindow, fourthWindow, fifthWindow, sixthWindow, seventhWindow];
const buttons = [firstButton, secondButton, thirdButton, fourthButton, fifthButton, sixthButton, seventhButton];

// get index of chosen Font
  const buttonValues = (x) => {
    return buttons.indexOf(x);
  };
//  get value of chosen Font
  const windowToOpen = (x) => {
    return Windows[buttonValues(x)];
  };

  if (document.getElementById("first-window")) {

    Windows.forEach(window => window.style.display = 'none');

    buttons.forEach(button => button.addEventListener('click', function() {
      Windows.forEach(window => window.style.zIndex = "30");
      const screenWidth = window.innerWidth;
      if ((windowToOpen(button).style.display === "none" && window.innerWidth <= 1100) || (windowToOpen(button).style.display === "block" && window.innerWidth <= 1100))  {
        Windows.forEach(window => window.style.display = 'none');
        windowToOpen(button).style.zIndex = "40";
        windowToOpen(button).style.display = "block";
      } else if (windowToOpen(button).style.display === "none" && window.innerWidth > 1100) {
        windowToOpen(button).style.zIndex = "40";
        windowToOpen(button).style.display = "block";
      } else {
        windowToOpen(button).style.display = "block";
      }
    }));
  }
}

export { openWindow }
